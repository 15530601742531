@font-face {
  font-family: 'IbarraRealNova-Regular';
  src: url('../assets/fonts/IbarraRealNova-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'IbarraRealNova-Italic';
  src: url('../assets/fonts/IbarraRealNova-Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'IbarraRealNova-SemiBold';
  src: url('../assets/fonts/IbarraRealNova-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'IbarraRealNova-SemiBoldItalic';
  src: url('../assets/fonts/IbarraRealNova-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: 'IbarraRealNova-Bold';
  src: url('../assets/fonts/IbarraRealNova-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'IbarraRealNova-BoldItalic';
  src: url('../assets/fonts/IbarraRealNova-BoldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'GothamPro-Regular';
  src: url('../assets/fonts/gothampro.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'GothamPro-Black';
  src: url('../assets/fonts/gothampro_black.ttf') format('truetype');
}
@font-face {
  font-family: 'GothamPro-BlackItalic';
  src: url('../assets/fonts/gothampro_blackitalic.ttf') format('truetype');
}
@font-face {
  font-family: 'GothamPro-Bold';
  src: url('../assets/fonts/gothampro_bold.ttf') format('truetype');
}
@font-face {
  font-family: 'GothamPro-BoldItalic';
  src: url('../assets/fonts/gothampro_bolditalic.ttf') format('truetype');
}
@font-face {
  font-family: 'GothamPro-Italic';
  src: url('../assets/fonts/gothampro_italic.ttf') format('truetype');
}
@font-face {
  font-family: 'GothamPro-Light';
  src: url('../assets/fonts/gothampro_light.ttf') format('truetype');
}
@font-face {
  font-family: 'GothamPro-LightItalic';
  src: url('../assets/fonts/gothampro_lightitalic.ttf') format('truetype');
}
@font-face {
  font-family: 'GothamPro-Medium';
  src: url('../assets/fonts/gothampro_medium.ttf') format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'GothamPro-MediumItalic';
  src: url('../assets/fonts/gothampro_mediumitalic.ttf') format('truetype');
}
@font-face {
  font-family: 'Alice-Regular';
  src: url('../assets/fonts/Alice-Regular.ttf') format('truetype');
}
